:root {
	--foreground-1: #d0c8c0;
	--foreground-2: #a6a09a;
	--foreground-3: #7d7873;
	--foreground-4: #53504d;

	--background-1: #404040;
	--background-2: #504840;
	--background-3: #61584F;
	--background-4: #726960;


	/* cactus green */
	/*
	--primary-link-color: #9fbfa6;
	--primary-link-hover: #b4d9bd;
	 */

	--menu-background: var(--background-2);
	--menu-background-hover: var(--background-3);

	/* mountain wheat */
	--primary-link-color: #c4bda7;
	--primary-link-hover: #d7d3c5;

	--primary-black: #2a332c;
	--primary-gray: #b0b0b0;
	--primary-white: #e0e0e0;

	--secondary-black: #3f4d42;
	--secondary-gray: #c0c0c0;
	--secondary-white: #f0f0f0;

	--text-black: var(--primary-black);
	--text-white: var(--primary-white);

	/*
	cactus: A0C0A8

	80%: #a9ccb1
	75%: #a0c0a8
	70%: #92b399
	50%: #6a806f
	30%: #3f4d42
	20%: #2a332c
	 */
}

body {
	color: var(--foreground-1);
	background-color: var(--background-1);

	margin: 0;
	padding: 0;

	font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
	'Oxygen', 'DejaVu Sans', 'Cantarell', 'Fira Sans', 'Droid Sans',
	'Helvetica Neue', 'sans-serif';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: var(--primary-link-color);
	text-decoration: none;
}

a:hover {
	color: var(--primary-link-hover);
}

code {
	font-family: 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', 'monospace';
}

h1 {
	font-size: 3rem;
	font-weight: normal;
	margin: 1rem 0 0 0;
	white-space: nowrap;
}

h2 {
	font-size: 2.5rem;
	font-weight: normal;
	margin: 1rem 0 0 0;
	white-space: nowrap;
}

h3 {
	font-size: 2rem;
	font-weight: normal;
	margin: 0.8rem 0 0 0;
	white-space: nowrap;
}

h4 {
	font-size: 1.8rem;
	font-weight: normal;
	margin: 0.7rem 0 0 0;
	white-space: nowrap;
}

h5 {
	font-size: 1.5rem;
	font-weight: normal;
	margin: 0.6rem 0 0 0;
	white-space: nowrap;
}

h6 {
	font-size: 1.2rem;
	font-weight: normal;
	margin: 0.5rem 0 0 0;
	white-space: nowrap;
}

.app {
	min-height: 100vh;

	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
}

.header {
	padding: 0.5rem;
	background-color: var(--background-2);

	align-self: stretch;

	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
}

.menu {
	align-self: stretch;

	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
}

.menuitem {
	background-color: var(--menu-background);
	margin: 0 1rem 0 0;
	padding: 0.2rem;
	display: flex;
	flex-flow: column nowrap;
}

.menuitem:hover {
	background-color: var(--menu-background-hover);
}

.content {
	/*max-width: 60rem;*/
	/*min-width: 10rem;*/

	padding: 0 1rem 0 1rem;

	flex: 100 1 auto;

	display: flex;
	flex-flow: column nowrap;
	/* along the main axis */
	justify-content: flex-start;
	/* along the cross axis */
	align-items: stretch;

}

.content table {
	margin: 0.5em 0;
	border-collapse: separate;
	border-spacing: 2px;
	background-color: var(--background-3);
}

.content td {
	padding: 0.4rem 0.6rem;
	background-color: var(--background-2);
}

.content .photo {
	max-width: 400px;
	max-height: 300px;
}

.hbox {
	display: flex;
	flex-flow: row nowrap;
}

.hbox-wrappable {
	display: flex;
	flex-flow: row wrap;
}

.row {
	align-self: stretch;

	display: flex;
	flex-flow: row nowrap;
	/* along the main axis */
	justify-content: flex-start;
	/* along the cross axis */
	align-items: flex-start;
}

.column {
	display: flex;
	flex-flow: column nowrap;
}

.row .column {
	flex-grow: 1;
}

.space-evenly {
	justify-content: space-evenly;
}

.left {
	align-self: flex-start;
}

.centered {
	align-self: center;
}

.right {
	align-self: flex-end;
}

.info {

}

.info .title {
	font-size: 2rem;
	margin: 0 0 0 -0.5rem;
}

.infoitem {
	background-color: var(--menu-background);
	margin: 0.5rem 1rem 0 0;
	padding: 0.5rem;

	white-space: nowrap;

	cursor: pointer;
}

.infoitem:hover {
	background-color: var(--menu-background-hover);
}

.twitter-follow-button {
	margin: 0.5rem 1rem 0 0;
}

.news {
	margin: 1rem 2rem;

	display: flex;
	flex-flow: column nowrap;
	/* along the main axis */
	justify-content: right;
	/* along the cross axis */
	align-items: flex-start;
}

@media (max-width: 960px) {
	.news {
		display: none;
	}
}

.news .title {
	font-size: 2rem;
	margin: 0 0 0 -0.5rem;
	white-space: nowrap;
}

.footer {
	padding: 0.5rem;
	background-color: var(--background-2);

	align-self: stretch;

	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.footer .copyright {
}

.footer .tagline {
}

.member {
}

.member .photo {
	margin: 0.5rem;
	width: 128px;
	height: 128px;
}

.member .badge {
	margin: 0.5rem;
	width: 128px;
	height: 128px;
}

.plans .icon {
	width: 128px;
	height: 96px;
}

.product img {
	width: 128px;
	height: auto;
	margin: 0 1em 0 0;
}

/* The adjustment styles need to stay at or near the bottom */
.center {
	align-items: center;
}

.no-margin {
	margin: 0;
}

.no-wrap {
	white-space: nowrap;
}

.announcement {
	background-color: var(--background-3);
	margin: 0.5rem 0;
	padding: 0 1rem;
	border-radius: 1rem;
}

.banner {
	display: flex;
	flex-flow: row nowrap;
	/* along the main axis */
	justify-content: flex-start;
	/* along the cross axis */
	align-items: flex-end;
}

.action-button {
	font-size: 1.5rem;
	color: var(--foreground-1);
	background-color: var(--background-1);

	margin: 0.5rem;
	border: 1px solid var(--primary-link-color);
	border-radius: 0.5rem;
	padding: 0.2rem 0.5rem;

	cursor: pointer;
}

.action-button:hover {
	background-color: var(--background-2);
	border: 1px solid var(--primary-link-hover);
}

.gap-2 {
	width: 2rem;
}

.season.season {
	background-color: var(--background-2);
}

.spring.spring {
	background-color: #008000;
}

.summer.summer {
	background-color: #808000;
}

.fall.fall {
	background-color: #806000;
}

.winter.winter {
	background-color: #4040a0;
}

img.embedded {
	margin: 0.5rem;
	border-radius: 0.5rem;
}

img.embedded.left {
	float: left;
}

img.embedded.right {
	float: right;
}

.venmo.qrcode {
	width: 5cm;
	height: 5cm;
}

.complete {
	color: var(--foreground-3);
	text-decoration: line-through;
}
